import IconProps from './IconProps';

function ArrowRight(props: IconProps) {
  const { width = 24, height = 24, fill = '#CF4500', ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path d="M6 12.2687H17.29" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0195 17.5373L17.2882 12.2687L12.0195 7" stroke={fill} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ArrowRight;
