import clsx from 'clsx';
import { Typography } from 'components/Typography';
import { Link } from 'components/Link';
import { IconButton } from 'components/IconButton';
import { Button } from 'components/Button';
import Close from 'icons/Close';
import MasterCardLogo from 'icons/MasterCardLogo';
import ArrowLeft from 'icons/ArrowLeft';
import Logout from 'icons/Logout';
import Menu from 'icons/Menu';

export function PublicHeader(props) {
  const { isLogoClickable, logoURL, showCloseIcon } = props;

  return (
    <header>
      <div
        className={clsx(
          'flex flex-col gap-6 p-6 pb-10 sm:relative md:h-[12.3vh] md:justify-center md:gap-0 md:border-b md:border-b-[#E3E3E3] md:p-0 md:pb-7 md:pt-8',
          !showCloseIcon && 'pt-[69px]'
        )}
      >
        {showCloseIcon && (
          <div className="flex justify-end sm:hidden">
            <IconButton title="Go to home page" component={Link} to="/" variant="text" color="text" data-testid="close-button">
              <Close />
            </IconButton>
          </div>
        )}

        <div className="relative flex items-center justify-start sm:mx-auto sm:w-3/4 sm:justify-center">
          <div className="absolute top-6 hidden justify-start sm:left-0 sm:top-1/2 sm:flex sm:-translate-y-1/2">
            <IconButton
              title="Go to home page"
              component={Link}
              to="/"
              variant="text"
              color="text"
              className="flex items-center gap-1"
              data-testid="close-button"
            >
              <ArrowLeft width={16} height={16} />
              <Typography variant="body3" component="span">
                Back
              </Typography>
            </IconButton>
          </div>

          <Link to={logoURL} variant="text" color="text" className={clsx(!isLogoClickable && 'pointer-events-none')}>
            <MasterCardLogo title="Mastercard Logo" ariaLabel="go to home page" />
          </Link>
        </div>
      </div>
    </header>
  );
}

export function ProtectedHeader(props) {
  const { isLogoClickable, logoURL, showBackButton, backText } = props;

  return (
    <header>
      <div className="sm:border-b sm:border-b-[#E3E3E3]">
        <div className="relative flex items-center justify-start p-6 pb-9 pt-16 sm:mx-auto sm:w-[90%] sm:justify-center sm:py-12 lg:w-[80%] xl:w-3/5 xl:max-w-[900px]">
          {showBackButton && (
            <div className="absolute top-6 flex justify-start sm:left-0 sm:top-1/2 sm:-translate-y-1/2">
              <IconButton
                title="Go to home page"
                component={Link}
                to="/"
                variant="text"
                color="text"
                className="flex items-center gap-1"
                data-testid="close-button"
              >
                <ArrowLeft width={16} height={16} />
                {!!backText && (
                  <Typography variant="body3" component="span">
                    {backText}
                  </Typography>
                )}
              </IconButton>
            </div>
          )}

          <Link to={logoURL} variant="text" color="text" className={clsx('flex', !isLogoClickable && 'pointer-events-none')}>
            <MasterCardLogo title="Mastercard Logo" ariaLabel="go to home page" />
          </Link>
        </div>
      </div>
    </header>
  );
}

export function PrivateHeader(props) {
  const { isLogoClickable, logoURL, showMenu, isMenuOpen, onMenuClick } = props;

  return (
    <header>
      <div className="sm:border-b sm:border-b-[#E3E3E3] md:flex md:h-[12.3vh] md:justify-center">
        <div className="flex items-center justify-between gap-7 p-6 pb-7 pt-[70px] sm:py-12 md:w-[90%] lg:w-[80%] xl:w-3/5 xl:max-w-[900px]">
          <Link to={logoURL} variant="text" color="text" className={clsx('flex', !isLogoClickable && 'pointer-events-none')}>
            <MasterCardLogo title="Mastercard Logo" ariaLabel="go to home page" />
          </Link>

          {showMenu && (
            <div className="sm:hidden">
              <IconButton title="Menu" component={Button} variant="text" color="text" className={clsx(!isMenuOpen && 'z-0')} onClick={onMenuClick}>
                <Menu />
              </IconButton>
            </div>
          )}

          <div className="hidden sm:relative sm:block xl:left-[100px]">
            <Link to="/logout" variant="text" color="text" className="flex items-center gap-3">
              <Logout fill="#141413" />
              <Typography variant="subheading" component="p" className="flex-1 text-left">
                Sign out
              </Typography>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
}

export function SharedComponentsHeader(props) {
  const { showClose, showHeader } = props;
  return (
    <header>
      {showHeader && (
        <div className="justify-center border-b border-b-[#E3E3E3] bg-[#F8F9F9]">
          <div className="flex items-center justify-center gap-7 p-6 pb-[20px] pt-[20px]">
            <MasterCardLogo title="Mastercard Logo" />
            {/*  { showClose && (
                    <div className="absolute ml-80" onClick={continueAsGuest}>
                        <Cross/>
                    </div>
                )}*/}
          </div>
        </div>
      )}
    </header>
  );
}
