import IconProps from './IconProps';

function Checkbox(props: IconProps) {
  const { width = 17, height = 16, ariaLabel, fill = 'none' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 16" aria-label={ariaLabel} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95691 12.1414C7.40822 12.1414 6.83681 12.0839 6.26059 11.9701L6.08808 11.9366L2.96975 13.8701L3.92454 11.1351L3.62025 10.9458C1.8868 9.86767 0.892473 8.25281 0.892473 6.51706C0.892473 3.41682 4.0611 0.893749 7.95565 0.893749C10.6918 0.893749 13.0686 2.13964 14.2414 3.95578C14.5576 3.90187 14.8835 3.86713 15.2142 3.84916C13.9671 1.5826 11.1841 0 7.95685 0C3.56854 0 0 2.92304 0 6.51698C0 8.4637 1.03266 10.2655 2.8476 11.5076L1.29263 15.9606L6.26302 12.8794C6.83685 12.9824 7.40589 13.0339 7.95694 13.0339C8.63498 13.0339 9.29269 12.9632 9.9216 12.8327C9.70956 12.5787 9.52267 12.3092 9.36215 12.0276C8.90692 12.1019 8.43732 12.1414 7.95694 12.1414L7.95691 12.1414ZM21.6161 16L17.379 14.1432L17.3789 14.1433C16.8123 14.293 16.2313 14.3685 15.6479 14.3685C12.5212 14.3685 9.97673 12.2731 9.97673 9.69863C9.97673 7.12285 12.5213 5.0288 15.6479 5.0288C18.7745 5.0288 21.319 7.1242 21.319 9.69863C21.319 10.8607 20.8111 11.9521 19.8815 12.8075L21.6161 16ZM15.6493 5.92502C13.015 5.92502 10.8718 7.61787 10.8718 9.69996L10.8718 9.70011C10.8718 11.7808 13.0149 13.4748 15.6493 13.476C16.2015 13.476 16.7538 13.3969 17.2893 13.2412L17.445 13.1956L19.587 14.1337L18.7472 12.5883L19.0395 12.3547C19.9344 11.6407 20.4268 10.6979 20.4268 9.69996C20.4268 7.61894 18.2836 5.92502 15.6493 5.92502ZM13.5659 9.86285C13.5659 10.7074 12.2984 10.7074 12.2984 9.86285C12.2984 9.01828 13.5659 9.01828 13.5659 9.86285ZM16.2865 9.86285C16.2865 10.7074 15.019 10.7074 15.019 9.86285C15.019 9.01828 16.2865 9.01828 16.2865 9.86285ZM19.0035 9.86285C19.0035 10.7074 17.736 10.7074 17.736 9.86285C17.736 9.01828 19.0035 9.01828 19.0035 9.86285Z"
      />
    </svg>
  );
}

export default Checkbox;
