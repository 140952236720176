/* istanbul ignore file */
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Loading } from 'components/Loading';

import Layout, { LayoutProps } from 'feature-components/Layout';
import ErrorScreen from 'feature-components/Error/ErrorScreen';

import { FocusTrap } from 'libs/focus-trap';

// eslint-disable-next-line react/display-name
const Loadable = (Component: React.ComponentType<unknown>) => (props: LayoutProps) => {
  return (
    <FocusTrap>
      <div className="flex h-full w-full flex-1 flex-col">
        <Layout {...props}>
          <Suspense fallback={<Loading show={true} />}>
            <ErrorBoundary FallbackComponent={ErrorScreen}>
              <Component />
            </ErrorBoundary>
          </Suspense>
        </Layout>
      </div>
    </FocusTrap>
  );
};

export default Loadable;
