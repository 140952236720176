/* istanbul ignore file */
import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import { Loading } from 'components/Loading';

import * as RouteConstants from 'constants/routes';

import Layout, { LayoutProps } from 'feature-components/Layout';
import ErrorScreen from 'feature-components/Error/ErrorScreen';

import { FocusTrap } from 'libs/focus-trap';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';

// eslint-disable-next-line react/display-name, @typescript-eslint/no-explicit-any
const Loadable = (Component: React.ComponentType<unknown>) => (props: LayoutProps) => {
  return (
    <FocusTrap>
      <div className="flex h-full w-full flex-1 flex-col">
        <Layout {...props}>
          <Suspense fallback={<Loading show={true} />}>
            <ErrorBoundary FallbackComponent={ErrorScreen}>
              <Component />
            </ErrorBoundary>
          </Suspense>
        </Layout>
      </div>
    </FocusTrap>
  );
};

const NotFound = Loadable(lazy(() => import('feature-components/Error/NotFound')));
const SystemDown = Loadable(lazy(() => import('feature-components/Error/SystemDown')));
const UnexpectedError = Loadable(lazy(() => import('feature-components/Error/UnexpectedError')));

const Landing = Loadable(lazy(() => import('pages/Landing')));
const AusLanding = Loadable(lazy(() => import('pages/Landing/Aus')));

const SignIn = Loadable(lazy(() => import('pages/SignIn')));
const AusSignIn = Loadable(lazy(() => import('pages/SignIn/Aus')));

const RUXAuthSuccess = Loadable(lazy(() => import('pages/RUX/AuthSuccess')));
const RUXAuthUnsuccess = Loadable(lazy(() => import('pages/RUX/AuthUnsuccess')));
const CreateAccount = Loadable(lazy(() => import('pages/CreateAccount')));
const AusCreateAccount = Loadable(lazy(() => import('pages/CreateAccount/Aus')));
const MagicLink = Loadable(lazy(() => import('pages/MagicLink')));
const AccountLocked = Loadable(lazy(() => import('pages/LockedAccount')));
const VerifyMagicLink = Loadable(lazy(() => import('pages/VerifyMagicLink')));
import TermsAndConditions from 'pages/TermsAndConditions';

const RUXMoreDetails = Loadable(lazy(() => import('pages/RUX/RUXMoreDetails')));
import OnboardingTermsConditions from 'pages/RUX/OnboardingTermsConditions';
const Onboarding = Loadable(lazy(() => import('pages/Onboarding')));
const Verify = Loadable(lazy(() => import('pages/Verify')));
const RUXVerify = Loadable(lazy(() => import('pages/RUX/RUXVerify')));
import Home1 from 'pages/Home_';
const Activity = Loadable(lazy(() => import('pages/Activity')));

import Accounts from 'pages/Accounts_';
const AccountsDetails = Loadable(lazy(() => import('pages/AccountsDetails')));
const AccountsRemoveDetail = Loadable(lazy(() => import('pages/AccountsRemoveDetails')));

const Apps = Loadable(lazy(() => import('pages/Apps')));
const AppDetails = Loadable(lazy(() => import('pages/AppDetails')));

const Profile = Loadable(lazy(() => import('pages/Profile')));
const PersonalInformation = Loadable(lazy(() => import('pages/PersonalInformation')));
const RecentTransactions = Loadable(lazy(() => import('pages/RecentTransactions')));
const Settings = Loadable(lazy(() => import('pages/Settings')));

const Support = Loadable(lazy(() => import('pages/Support')));

const Logout = Loadable(lazy(() => import('pages/Logout')));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function AppRoutes() {
  const { features } = useFeatureFlagState();
  const market = features?.MARKET;

  return (
    <SentryRoutes>
      <Route
        path={RouteConstants.TERMS_CONDITIONS}
        element={<TermsAndConditions routeType="PUBLIC" showHeader={true} showFooter={true} showHeaderCloseIcon={false} className="mb-10 h-5/6 sm:w-[700px]" />}
      />
      <Route
        path={RouteConstants.RUX_AUTH_SUCCESS}
        element={
          <RUXAuthSuccess
            routeType="PROTECTED"
            showSubHeader={false}
            showHeaderCloseIcon={false}
            showHeader={true}
            className=""
            headerClassName={'hidden sm:block '}
          />
        }
      />
      <Route
        path={RouteConstants.RUX_AUTH_UNSUCCESS}
        element={<RUXAuthUnsuccess routeType="PROTECTED" showHeader={true} headerClassName={'hidden sm:block '} />}
      />
      <Route path={RouteConstants.RUX_VERIFY} element={<RUXVerify routeType="PROTECTED" headerClassName={'sm:block'} />} />
      {market === 'AUS' ? (
        <>
          <Route
            path={RouteConstants.LANDING}
            element={<AusLanding routeType="PUBLIC" showHeader={false} className="" parentClassName="sm:overflow-visible" />}
          />
          <Route path={RouteConstants.SIGN_IN} element={<AusSignIn routeType="PUBLIC" />} />
          <Route path={RouteConstants.CREATE_ACCOUNT_AUS} element={<AusCreateAccount routeType="PUBLIC" />} />
        </>
      ) : (
        <>
          <Route path={RouteConstants.LANDING} element={<Landing routeType="PUBLIC" showHeader={false} className="" parentClassName="sm:overflow-visible" />} />
          <Route path={RouteConstants.CREATE_ACCOUNT} element={<CreateAccount routeType="PUBLIC" />} />
          <Route path={RouteConstants.SIGN_IN} element={<SignIn routeType="PUBLIC" />} />
        </>
      )}

      <Route path={RouteConstants.HOME} element={<Home1 routeType="PRIVATE" />} />
      <Route path={RouteConstants.ACCOUNTS} element={<Accounts routeType="PRIVATE" />} />
      <Route path={RouteConstants.MAGIC_LINK} element={<MagicLink routeType="PUBLIC" />} />
      <Route path={RouteConstants.ACCOUNT_LOCKED} element={<AccountLocked routeType="PUBLIC" />} />

      <Route path={RouteConstants.VERIFY} element={<VerifyMagicLink routeType="PUBLIC" showHeader={false} showFooter={false} />} />
      <Route path={RouteConstants.SUPPORT} element={<Support routeType="PUBLIC" className="mb-10 h-5/6 md:max-w-[700px]" />} />
      <Route path="/protected/support" element={<Support routeType="PROTECTED" className="md:max-w-[700px]" />} />

      <Route path={RouteConstants.RUX_MORE_DETAILS} element={<RUXMoreDetails routeType="PROTECTED" />} />
      <Route
        path={RouteConstants.ONBOARDING_TERMS_CONDITIONS}
        element={<OnboardingTermsConditions routeType="PROTECTED" className="sm:w-[700px]" showBackButton={market != 'AUS'} backText="Back to cancel" />}
      />

      <Route path={RouteConstants.ONBOARDING} element={<Onboarding routeType="PROTECTED" headerClassName={'sm:block'} />} />
      <Route path={RouteConstants.VERIFY_OTP} element={<Verify routeType="PROTECTED" headerClassName={'sm:block'} />} />

      <Route path="/home/activity" element={<Activity routeType="PRIVATE" backText="Back" showMobileHeader={false} showSubHeader={true} />} />

      <Route path="/accounts/:id" element={<AccountsDetails routeType="PRIVATE" backText="Back" showSubHeader={true} showMobileHeader={false} />} />
      <Route path="/accounts/:id/remove" element={<AccountsRemoveDetail routeType="PRIVATE" backText="Back" showMobileHeader={false} showSubHeader={true} />} />

      <Route path={RouteConstants.APPS} element={<Apps routeType="PRIVATE" />} />
      <Route path="/apps/:id" element={<AppDetails routeType="PRIVATE" backText="Back" showMobileHeader={false} showSubHeader={true} />} />

      <Route path={RouteConstants.PROFILE} element={<Profile routeType="PRIVATE" />} />
      <Route path="/profile/personal-information" element={<PersonalInformation routeType="PRIVATE" showMobileHeader={false} showSubHeader={true} />} />
      <Route path="/profile/settings" element={<Settings routeType="PRIVATE" showMobileHeader={false} showSubHeader={true} className="p-0 sm:p-6" />} />
      <Route path="/profile/support" element={<Support routeType="PRIVATE" showMobileHeader={false} showSubHeader={true} />} />

      <Route path="/transactions/:id" element={<RecentTransactions routeType="PRIVATE" backText="Back" showMobileHeader={false} showSubHeader={true} />} />
      <Route path={RouteConstants.LOGOUT} element={<Logout routeType="PRIVATE" />} />

      <Route path={RouteConstants.SYSTEM_DOWN} element={<SystemDown routeType="ERROR" />} />
      <Route path={RouteConstants.UNEXPECTED_ERROR} element={<UnexpectedError routeType="PROTECTED" />} />
      <Route path="*" element={<NotFound routeType="ERROR" />} />
      {/*      <Route path="/return-user" element={<ReturnUserCheck routeType="SHARED" showSubHeader={false}/>} />
        <Route path="/verify-mfa" element={<MFAVerification routeType="SHARED" showSubHeader={true} showClose={true}/>} />
        <Route path="/add-email" element={<GetEmail routeType="SHARED" showSubHeader={false} showClose={true} />}/>
        <Route path="/continue-as-guest" element={<ContinueAsGuest routeType="SHARED" showSubHeader={false} showClose={false} />}/>*/}
    </SentryRoutes>
  );
}

export default AppRoutes;
