import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

// Components
import { Typography } from 'components/Typography';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton';
import { TransitionUp, TransitionFade } from 'components/Transition';

// Icons
import MasterCardLogo from 'icons/MasterCardLogo';
import LearnMoreIllustration from 'icons/LearnMoreIllustration';
import Connect from 'icons/Connect';
import FingerprintLock from 'icons/FingerprintLock';
import ArrowRight from 'icons/ArrowRight';
import SecuredByMA from 'icons/SecuredByMA';
import Close from 'icons/Close';

import { useWindowSize } from 'hooks/useWindowSize';
import LinkChain from 'icons/LinkChain';
import KeyIcon from 'icons/Key';

interface Props {
  open: boolean;
  onClose: () => void;
  onContinueClick?: () => void;
}

function LearnMoreModal(props: Props) {
  const { t } = useTranslation();
  const { screenSize } = useWindowSize();
  const { open, onClose, onContinueClick } = props;

  const isFullScreenModal = screenSize === 'sm';

  return (
    <Modal
      show={open}
      fullScreen={isFullScreenModal}
      TransitionComponent={isFullScreenModal ? TransitionUp : TransitionFade}
      className={clsx('z-50 flex flex-col bg-white', !isFullScreenModal && 'top-1/2 max-w-[500px] -translate-y-1/2')}
      aria-modal="true"
    >
      {isFullScreenModal && (
        <div className="relative flex justify-center rounded-t-lg bg-gray-lightest py-5">
          <MasterCardLogo />
          <Button
            data-testid="test-close-btn"
            variant="text"
            color="text"
            underline
            className="absolute right-0 mr-5 text-14-24 font-bold !text-feedback-progress"
            onClick={onClose}
          >
            {t('common.learn-more.close')}
          </Button>
        </div>
      )}

      {!isFullScreenModal && (
        <div className="flex justify-end">
          <IconButton title="Go to home page" component={Button} variant="text" color="text" data-testid="test-close-btn" onClick={onClose}>
            <Close ariaLabel="close modal" />
          </IconButton>
        </div>
      )}

      <div className="flex flex-1 flex-col items-center gap-4 overflow-auto px-7 pt-10">
        <LearnMoreIllustration ariaLabel="click to view learn more" />

        <Typography variant="body1" component="p" className="text-helper">
          {t('common.learn-more.description')}
        </Typography>

        <div className="flex flex-col gap-5 rounded-md border border-solid border-border-light p-6">
          <div className="flex items-center gap-5">
            <Connect width="24" height="24" />
            <Typography variant="body1" component="p" className="flex-1 !font-medium text-helper">
              {t('common.learn-more.feature-1')}
            </Typography>
          </div>
          <div className="flex items-center gap-5">
            <LinkChain width="24" height="24" />
            <Typography variant="body1" component="p" className="flex-1 !font-medium text-helper">
              {t('common.learn-more.feature-2')}
            </Typography>
          </div>
          <div className="flex items-center gap-5">
            <FingerprintLock width="24" height="24" />
            <Typography variant="body1" component="p" className="flex-1 !font-medium text-helper">
              {t('common.learn-more.feature-3')}
            </Typography>
          </div>
          <div className="flex items-center gap-5">
            <KeyIcon width="24" height="24" />
            <Typography variant="body1" component="p" className="flex-1 !font-medium text-helper">
              {t('common.learn-more.feature-4')}
            </Typography>
          </div>

          {onContinueClick && (
            <Button data-testid="test-continue-btn" variant="link" color="text" className="flex items-center font-medium" onClick={onContinueClick}>
              {t('common.learn-more.continue')} <ArrowRight ariaLabel="go back" />
            </Button>
          )}
        </div>
      </div>

      <div className="flex w-full justify-center p-4 text-sm">
        <SecuredByMA ariaLabel="secured by mastercard" />
      </div>
    </Modal>
  );
}

export default LearnMoreModal;
