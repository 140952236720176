import IconProps from './IconProps';

function HotelBell(props: IconProps) {
  const { width = 24, height = 24, fill = '#CF4500', ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5333 1.90293V6.19947L24 8.71893L23.3739 9.5808L12 1.3184L0.626133 9.5808L0 8.71893L12 0L19.4667 5.42507V1.90293H20.5333ZM15.2 22.1696H21.6V10.4363H22.6667V23.2363H14.1333V14.7029H9.86667V23.2363H1.33333V10.4363H2.4V22.1696H8.8V13.6363H15.2V22.1696Z"
        fill={fill}
      />
    </svg>
  );
}

export default HotelBell;
