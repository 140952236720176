import React, { ReactNode, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Loading } from 'components/Loading';
import { IconButton } from 'components/IconButton';
import { Button } from 'components/Button';

import * as RouteConstants from 'constants/routes';

import { PublicHeader, ProtectedHeader, PrivateHeader, SharedComponentsHeader } from 'feature-components/Header';
import Footer from 'feature-components/Footer';
import { MobileNavigation, DesktopNavigation } from 'feature-components/Navigation';
import SessionTimeout from 'feature-components/SessionTimeout';

import { useToggle } from 'hooks/useToggle';

import ArrowLeft from 'icons/ArrowLeft';
import { useGetUserStatus } from 'services';
import Menu from 'icons/Menu';
import { Typography } from 'components/Typography';
import { appStorage } from 'utils/storage';
import SecuredByMA from 'icons/SecuredByMA';

type ROUTE_TYPE = 'PUBLIC' | 'PROTECTED' | 'PRIVATE' | 'ERROR' | 'SHARED';

export interface LayoutProps {
  routeType: ROUTE_TYPE;
  className?: string;
  showHeader?: boolean;
  isLogoClickable?: boolean;
  logoURL?: string;
  showSubHeader?: boolean; // NOTE: Added for private sub routes.
  showClose?: boolean; // NOTE: Added for shared sub routes.
  showMenu?: boolean;
  showHeaderCloseIcon?: boolean; // NOTE: Added because of onaboarding, verify screen.
  showFooter?: boolean;
  children?: ReactNode;
  backText?: string;
  headerClassName?: string;
  parentClassName?: string;
  showMobileHeader?: boolean;
  showBackButton?: boolean;
}

const DEFAULT_PUBLIC_LAYOUT_PROPS: Omit<LayoutProps, 'children'> = {
  routeType: 'PUBLIC',
  className: 'max-w-[390px]',
  showHeader: true,
  isLogoClickable: true,
  logoURL: '/',
  showSubHeader: false,
  showMenu: false,
  showHeaderCloseIcon: true,
  showFooter: true,
};

const DEFAULT_PROTECTED_LAYOUT_PROPS: Omit<LayoutProps, 'children'> = {
  routeType: 'PROTECTED',
  className: 'max-w-[390px]',
  showHeader: true,
  isLogoClickable: false,
  showSubHeader: false,
  showMenu: false,
  showHeaderCloseIcon: false,
  showFooter: true,
  showBackButton: false,
};

const DEFAULT_PRIVATE_LAYOUT_PROPS: Omit<LayoutProps, 'children'> = {
  routeType: 'PRIVATE',
  className: '',
  showHeader: true,
  showMobileHeader: true,
  isLogoClickable: true,
  showSubHeader: false,
  showMenu: true,
  showHeaderCloseIcon: false,
  showFooter: true,
};

const DEFAULT_SHARED_COMPONENTS_LAYOUT_PROPS: Omit<LayoutProps, 'children'> = {
  routeType: 'SHARED',
  className: 'max-w-[390px]',
  showHeader: false,
  showClose: false,
  showMobileHeader: true,
  isLogoClickable: false,
  showSubHeader: false,
  showMenu: false,
  showHeaderCloseIcon: false,
  showFooter: false,
};

function getLayoutPropsByRouteType(routeType: ROUTE_TYPE, options: Omit<LayoutProps, 'routeType'>): LayoutProps {
  let layoutProps;

  if (routeType === 'PUBLIC') layoutProps = DEFAULT_PUBLIC_LAYOUT_PROPS;
  if (routeType === 'PROTECTED') layoutProps = DEFAULT_PROTECTED_LAYOUT_PROPS;
  if (routeType === 'PRIVATE') layoutProps = DEFAULT_PRIVATE_LAYOUT_PROPS;
  if (routeType === 'SHARED') layoutProps = DEFAULT_SHARED_COMPONENTS_LAYOUT_PROPS;

  return {
    ...layoutProps,
    ...options,
  } as LayoutProps;
}

const PublicLayout = (props: Omit<LayoutProps, 'routeType'>) => {
  const { className, showHeader, isLogoClickable, logoURL, showMenu, showHeaderCloseIcon, showFooter, children, parentClassName } = props;
  return (
    <>
      {showHeader && <PublicHeader isLogoClickable={isLogoClickable} logoURL={logoURL} showMenu={showMenu} showCloseIcon={showHeaderCloseIcon} />}

      <main className={clsx('flex flex-1 flex-col items-center overflow-hidden', parentClassName)}>
        <div className={clsx('h-full w-full', className)}>{children}</div>
      </main>
      {showFooter && <Footer />}
    </>
  );
};

const ProtectedLayout = (props: Omit<LayoutProps, 'routeType'>) => {
  const { className, showHeader, isLogoClickable, logoURL, showFooter, showBackButton, backText, children } = props;

  return (
    <>
      {showHeader && <ProtectedHeader isLogoClickable={isLogoClickable} logoURL={logoURL} showBackButton={showBackButton} backText={backText} />}

      <main className="flex flex-1 flex-col items-center overflow-hidden">
        <div className={clsx('flex h-full w-full flex-col p-6 pt-0', className)}>{children}</div>
      </main>

      {showFooter && <Footer />}

      <SessionTimeout />
    </>
  );
};

const SharedComponentsLayout = (props: Omit<LayoutProps, 'routeType'>) => {
  const { showSubHeader, showClose, className, children, showHeader, showFooter } = props;
  return (
    <>
      <SharedComponentsHeader showSubHeader={showSubHeader} showClose={showClose} showHeader={showHeader} />

      <main className="scrollbar-hide flex flex-1 flex-col items-center overflow-auto">
        <div className={clsx('flex h-full w-full flex-col', className)}>{children}</div>
      </main>
      {showFooter && (
        <div className="flex h-14 items-center justify-center">
          <SecuredByMA />
        </div>
      )}
    </>
  );
};

const PrivateLayout = (props: Omit<LayoutProps, 'routeType'>) => {
  const { className, showHeader, isLogoClickable, logoURL, showSubHeader, showMenu, showHeaderCloseIcon, showFooter, backText, children, showMobileHeader } =
    props;

  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 640;
  const [isNavigationModalOpen, onNavigationModalToggle] = useToggle(false);
  const { pathname } = useLocation();

  const isNoTopSpace = useCallback(
    () => !/^(\/accounts\/\d+\/remove)|(\/transactions\/\d+)|(\/accounts\/\d+)|(\/home\/activity)|(\/apps\/\d+)$/gi.test(pathname),
    [pathname]
  );

  return (
    <>
      {((showHeader && !isMobile) || (showMobileHeader && isMobile)) && (
        <PrivateHeader
          isLogoClickable={isLogoClickable}
          logoURL={logoURL}
          showMenu={showMenu}
          showCloseIcon={showHeaderCloseIcon}
          isMenuOpen={isNavigationModalOpen}
          onMenuClick={onNavigationModalToggle}
        />
      )}

      {showSubHeader && (
        <div className="sm:hidden">
          <div className="flex justify-between gap-7 p-6 pb-8 pt-[69px]">
            <IconButton
              title="Back"
              component={Button}
              onClick={() => navigate(-1)}
              variant="text"
              color="text"
              data-testid="back-button"
              className="flex flex-row items-center justify-center"
            >
              <ArrowLeft ariaLabel="go back" />
              {!!backText && (
                <Typography variant="body1" component="p" className="pl-2 sm:hidden">
                  {backText}
                </Typography>
              )}
            </IconButton>
            <div className="sm:hidden">
              <IconButton
                title="Menu"
                component={Button}
                variant="text"
                color="text"
                className={clsx(!isNavigationModalOpen && 'z-0')}
                onClick={onNavigationModalToggle}
              >
                <Menu ariaLabel="view menu" />
              </IconButton>
            </div>
          </div>
        </div>
      )}

      <main className="flex flex-1 flex-col items-center overflow-auto sm:overflow-hidden">
        <div
          className={clsx(
            `flex h-full w-full flex-col p-6 pt-0 sm:flex-row sm:gap-10 md:w-[90%] lg:w-[80%] xl:w-3/5 xl:max-w-[900px] ${
              isNoTopSpace() && 'sm:pt-10 md:pt-20'
            }`,
            className
          )}
        >
          <div
            className={`hidden h-fit w-full min-w-[198px] max-w-[256px] flex-col rounded-[6px] shadow-0-2-10-blk-0.2 sm:flex ${!isNoTopSpace() && 'md:mt-20'}`}
          >
            <DesktopNavigation />
          </div>

          <div className="relative flex h-full w-full flex-1 flex-col sm:overflow-auto">{children}</div>
        </div>
      </main>

      {showFooter && <Footer />}

      <div className="sm:hidden">
        <MobileNavigation open={isNavigationModalOpen} onClose={onNavigationModalToggle} />
      </div>

      <SessionTimeout />
    </>
  );
};

const Layout = (props: LayoutProps) => {
  const { routeType: _routeType, ...restProps } = props;
  let routeType = _routeType;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userStatus = useGetUserStatus();
  //const isRux = readCookie('flow') === 'rux';
  const isRux = appStorage.getItem('flow') === 'rux';
  const { isLoading, isMagicLinkExpired, isMagicLinkVerified, isUserVerified, navigateTo, isError } = userStatus;

  if (routeType === 'ERROR') isUserVerified ? (routeType = 'PRIVATE') : (routeType = 'PUBLIC');

  useEffect(() => {
    if (!isRux) {
      if (isError && pathname === RouteConstants.UNEXPECTED_ERROR && routeType === 'PROTECTED') {
        return;
      }

      if (routeType !== 'PROTECTED' && pathname === RouteConstants.UNEXPECTED_ERROR && navigateTo === RouteConstants.ONBOARDING) {
        navigate(pathname);
      }

      if (isLoading || routeType === 'ERROR') return;

      if (routeType !== 'PUBLIC' && !isMagicLinkVerified) {
        navigate(RouteConstants.SIGN_IN, { state: { from: pathname } });
      }

      if (routeType !== 'PUBLIC' && isMagicLinkExpired) {
        navigate('/magic-link?expired', { state: { from: pathname } });
      }

      if (routeType !== 'PROTECTED' && !isUserVerified && navigateTo) {
        navigate(navigateTo);
      }

      if (routeType !== 'PRIVATE' && isUserVerified && navigateTo) {
        navigate(navigateTo);
      }
    }
  }, [userStatus, routeType, pathname]);

  let LayoutComponent = PublicLayout;

  if (routeType === 'PUBLIC') LayoutComponent = PublicLayout;
  if (routeType === 'PROTECTED') LayoutComponent = ProtectedLayout;
  if (routeType === 'PRIVATE') LayoutComponent = PrivateLayout;
  if (routeType === 'SHARED') LayoutComponent = SharedComponentsLayout;

  const layoutProps = getLayoutPropsByRouteType(routeType, restProps);

  return (
    <Loading show={isLoading} delay={-1}>
      <LayoutComponent logoURL={navigateTo} {...layoutProps} />
    </Loading>
  );
};

export default Layout;
