import { create } from 'zustand';

interface ToggleLearnMoreModalState {
  isLearnMoreModalOpen: boolean;
  toggleLearnMoreModal: () => void;
}

export const useToggleLearnMoreModal = create<ToggleLearnMoreModalState>((set) => {
  return {
    isLearnMoreModalOpen: false,
    toggleLearnMoreModal: () => set((state) => ({ ...state, isLearnMoreModalOpen: !state.isLearnMoreModalOpen })),
  };
});
