import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton';
import { TransitionRight } from 'components/Transition';

import * as RouteConstants from 'constants/routes';

import Home from 'icons/Home';
import AccountBalance from 'icons/AccountBalance';
import NavApp from 'icons/NavApp';
import AccountCircleOutlined from 'icons/AccountCircleOutlined';
import Close from 'icons/Close';
import Logout from 'icons/Logout';
import Chat from 'icons/Chat';

import { useGetActivities, useGetAccounts } from 'services';

import NavLink from './NavLink';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';

const Navigation = () => {
  const { data: activitiesData } = useGetActivities();
  const { data: accountsData, error: accountsError } = useGetAccounts();
  const allFeatures = useFeatureFlagState();
  const market = allFeatures.features?.MARKET;

  return (
    <nav className="flex flex-1 flex-col">
      {((activitiesData && !!activitiesData.length && !accountsError) || (accountsData && !!accountsData?.institutions?.length && !accountsError)) && (
        <NavLink to={RouteConstants.HOME} label="Home" icon={<Home fill="currentColor" ariaLabel="navigate to home" />} />
      )}
      <NavLink to={RouteConstants.ACCOUNTS} label="Accounts" icon={<AccountBalance fill="currentColor" ariaLabel="navigate to accounts page" />} />
      {market != 'AUS' && <NavLink to={RouteConstants.APPS} label="Apps" icon={<NavApp fill="currentColor" ariaLabel="navigate to apps page" />} />}
      <NavLink to={RouteConstants.PROFILE} label="Profile" icon={<AccountCircleOutlined fill="currentColor" ariaLabel="navigate to profile page" />} />
      <div className="mt-auto">
        <NavLink to={RouteConstants.LOGOUT} label="Sign out" icon={<Logout ariaLabel="log out" />} className="!text-[#CF4500] sm:hidden" />
        {market != 'AUS' && (
          <NavLink
            to="/profile/support"
            label="Support"
            icon={<Chat width={24} height={23} fill="#CF4500" ariaLabel="navigate to support" />}
            className="!text-[#CF4500] sm:hidden"
          />
        )}
      </div>
    </nav>
  );
};

export const MobileNavigation = (props) => {
  const { open, onClose } = props;

  return (
    <Modal
      show={open}
      fullScreen
      showBackdrop
      onClose={onClose}
      TransitionComponent={TransitionRight}
      className="left-[unset] right-0 flex !w-2/3 rounded-t-none bg-white"
    >
      <div className="flex items-end justify-end p-6 pt-11">
        <IconButton title="Close" component={Button} variant="text" color="text" className="" onClick={onClose}>
          <Close ariaLabel="close modal" />
        </IconButton>
      </div>
      <Navigation />
    </Modal>
  );
};

export const DesktopNavigation = () => {
  return <Navigation />;
};
