import IconProps from './IconProps';

function Connect(props: IconProps) {
  const { width = 25, height = 26, ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2716 10.3114C24.2716 7.18562 21.8324 4.64129 18.8337 4.64129C16.7248 4.64129 14.8957 5.90315 13.9949 7.73717L7.35776 4.96913C7.42103 4.69799 7.46355 4.41758 7.46355 4.12583C7.46355 2.13614 5.91128 0.517578 4.00308 0.517578C2.09487 0.517578 0.542603 2.13614 0.542603 4.12583C0.542603 6.11552 2.09487 7.73407 4.00308 7.73407C5.28048 7.73407 6.38487 7.00108 6.98501 5.9248L13.6242 8.69387C13.4779 9.20727 13.3958 9.74954 13.3958 10.3114C13.3958 11.3794 13.6855 12.3763 14.1808 13.23L6.46891 19.1269C5.84108 18.4599 4.96805 18.0434 4.00308 18.0434C2.09487 18.0434 0.542603 19.6619 0.542603 21.6516C0.542603 23.6413 2.09487 25.2598 4.00308 25.2598C5.91128 25.2598 7.46355 23.6413 7.46355 21.6516C7.46355 21.0392 7.30239 20.4712 7.04434 19.966L14.7661 14.0609C15.6599 15.1145 16.9205 15.8207 18.3393 15.9547V18.0949C16.6664 18.3475 15.3732 19.8382 15.3732 21.6506C15.3732 23.6403 16.9255 25.2588 18.8337 25.2588C20.7419 25.2588 22.2941 23.6403 22.2941 21.6506C22.2941 19.8382 21.0009 18.3475 19.328 18.0949V15.9547C22.0954 15.6939 24.2716 13.2629 24.2716 10.3114ZM4.00308 6.70315C2.64064 6.70315 1.53131 5.54748 1.53131 4.12583C1.53131 2.70418 2.64064 1.54851 4.00308 1.54851C5.36551 1.54851 6.47484 2.70418 6.47484 4.12583C6.47484 5.54748 5.36551 6.70315 4.00308 6.70315ZM4.00308 24.2289C2.64064 24.2289 1.53131 23.0733 1.53131 21.6516C1.53131 20.23 2.64064 19.0743 4.00308 19.0743C5.36551 19.0743 6.47484 20.23 6.47484 21.6516C6.47484 23.0733 5.36551 24.2289 4.00308 24.2289ZM14.3845 10.3114C14.3845 7.75366 16.3807 5.67222 18.8337 5.67222C21.2867 5.67222 23.2829 7.75366 23.2829 10.3114C23.2829 12.8691 21.2867 14.9506 18.8337 14.9506C16.3807 14.9506 14.3845 12.8691 14.3845 10.3114ZM21.3054 21.6516C21.3054 23.0733 20.1961 24.2289 18.8337 24.2289C17.4712 24.2289 16.3619 23.0733 16.3619 21.6516C16.3619 20.23 17.4712 19.0743 18.8337 19.0743C20.1961 19.0743 21.3054 20.23 21.3054 21.6516Z"
        fill="#25836D"
      />
    </svg>
  );
}

export default Connect;
