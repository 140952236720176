import { Link as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import * as RouteConstants from 'constants/routes';
import LearnMoreModal from 'feature-components/LearnMoreModal';
import { Typography } from 'components/Typography';
import { useGetTermsAndConditions, useGetUserLoggedInStatus, useGetUserStatus } from 'services';
import { useFeatureFlagState } from 'hooks/useFeatureFlag';
import { getCurrentYear } from 'utils/time';
import { Link } from 'components/Link';
import Chat from 'icons/Chat';
import SecuredByMA from 'icons/SecuredByMA';
import { useToggleLearnMoreModal } from 'feature-components/LearnMoreModal/useToggleLearnMoreModal';

function Footer() {
  const location = useLocation();
  const { data } = useGetTermsAndConditions();
  const { isLearnMoreModalOpen, toggleLearnMoreModal } = useToggleLearnMoreModal();
  const { authStatus } = useGetUserStatus();
  const { features } = useFeatureFlagState();
  const market = features?.MARKET;
  const isUserLoggedIn = useGetUserLoggedInStatus();

  const showSecuredByMc = () => {
    const pathName = location.pathname;
    if (market === 'AUS') {
      return !isUserLoggedIn && !isLearnMoreModalOpen;
    }
    return !isUserLoggedIn && !isLearnMoreModalOpen && pathName === '/';
  };

  const showNeedHelpLoggingIn = () => {
    const routeTypesShouldShow = ['PUBLIC', 'PROTECTED'];

    if (market === 'AUS') {
      return false;
    }
    const shouldShow = !isUserLoggedIn && !isLearnMoreModalOpen;
    return shouldShow && routeTypesShouldShow.includes(authStatus ?? '');
  };

  let supportLink = '/support';

  if (authStatus === 'PUBLIC') supportLink = '/support';
  if (authStatus === 'PROTECTED') supportLink = '/protected/support';
  if (authStatus === 'PRIVATE') supportLink = '/profile/support';

  return (
    <footer className={clsx('hidden flex-col justify-end gap-y-6 px-6 pb-6 pt-8 tracking-wider sm:flex')}>
      <section className="flex justify-around">
        <div className="flex justify-between gap-x-[30px] text-sm uppercase leading-[17px]">
          <RouterLink
            to="#"
            data-testid="test-about-btn"
            onClick={() => {
              toggleLearnMoreModal();
            }}
          >
            <Typography className="uppercase" component="p" variant="footer2">
              about
            </Typography>
          </RouterLink>
          {market != 'AUS' && (
            <>
              <Typography component="p" variant="footer3" className="text-[16px] leading-[19px]" aria-hidden="true">
                •
              </Typography>
              <RouterLink to={supportLink}>
                <Typography component="p" variant="footer2">
                  support
                </Typography>
              </RouterLink>
            </>
          )}
          <Typography component="p" variant="footer3" className="text-[16px] leading-[19px]" aria-hidden="true">
            •
          </Typography>

          <a href={RouteConstants.TERMS_CONDITIONS} target="_blank" rel="noreferrer">
            <Typography component="p" variant="footer2">
              Terms and conditions
            </Typography>
          </a>
          <Typography component="p" variant="footer3" className="text-[16px] leading-[19px]" aria-hidden="true">
            •
          </Typography>
          <a href={data?.privacyPolicyUrl || 'https://www.finicity.com/privacy/'} target="_blank" rel="noreferrer">
            <Typography component="p" variant="footer2">
              privacy
            </Typography>
          </a>
        </div>
      </section>
      <section className="flex justify-center gap-6 align-baseline text-[14px] leading-[17px]">
        <Typography className="text-center" component="p" variant="footer1">
          Copyright <span aria-hidden="true">©</span> {getCurrentYear()} Mastercard. All rights reserved.
        </Typography>

        {showNeedHelpLoggingIn() && (
          <div className="flex gap-2">
            <Chat fill="#cf4500" />
            <Typography variant="body1" component="p">
              <Link to="/support" variant="link" color="text">
                Need help signing in?
              </Link>
            </Typography>
          </div>
        )}
      </section>
      {showSecuredByMc() && (
        <div className="flex w-full justify-center text-sm">
          <SecuredByMA />
        </div>
      )}
      <LearnMoreModal open={isLearnMoreModalOpen} onClose={toggleLearnMoreModal} onContinueClick={toggleLearnMoreModal} />
    </footer>
  );
}

export default Footer;
