import IconProps from './IconProps';

function Logout(props: IconProps) {
  const { width = 23, height = 24, fill = '#CF4500', ariaLabel } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label={ariaLabel}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9091 0H19.0909C21.1964 0 22.9091 1.71273 22.9091 3.81818V20.1818C22.9091 22.2873 21.1964 24 19.0909 24H10.9091V22.9091H19.0909C20.5942 22.9091 21.8182 21.6862 21.8182 20.1818V3.81818C21.8182 2.31382 20.5942 1.09091 19.0909 1.09091H10.9091V0ZM7.71382 5.27236L6.94145 4.50109L0 11.4425L6.94145 18.3862L7.71382 17.6138L2.09891 12H17.1458V10.9091H2.076L7.71382 5.27236Z"
        fill={fill}
      />
    </svg>
  );
}

export default Logout;
